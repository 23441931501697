var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "requestEmployees__step2" }, [
    _c(
      "div",
      { staticClass: "amount" },
      [
        _c("label", { attrs: { for: "request-employees-amount" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.amount.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("select-box", {
          attrs: {
            id: "request-employees-amount",
            placeholder: _vm.$t("requestEmployees.form.amount.placeholder"),
            required: "required",
            error: _vm.amountErrorMessage,
            items: _vm.amountOptions,
          },
          model: {
            value: _vm.data.amount,
            callback: function ($$v) {
              _vm.$set(_vm.data, "amount", $$v)
            },
            expression: "data.amount",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dates" },
      [
        _c("label", { attrs: { for: "request-employees-dates-start" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.dates.start.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-dates-start",
            type: "date",
            placeholder: _vm.$t(
              "requestEmployees.form.dates.start.placeholder"
            ),
          },
          model: {
            value: _vm.data.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.data, "startDate", $$v)
            },
            expression: "data.startDate",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dates" },
      [
        _c("label", { attrs: { for: "request-employees-dates-end" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.dates.end.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-dates-end",
            type: "date",
            placeholder: _vm.$t("requestEmployees.form.dates.end.placeholder"),
          },
          model: {
            value: _vm.data.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.data, "endDate", $$v)
            },
            expression: "data.endDate",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c(
          "btn",
          {
            attrs: { "is-disabled": !_vm.isFormValid },
            nativeOn: {
              click: function ($event) {
                return _vm.onButtonClick.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("requestEmployees.form.step2.submit")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }