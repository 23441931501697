



























import {maxLength} from '@labor-digital/helferlein/lib/Strings/maxLength';
import BaseChips from '@labor-digital/vuetiful/src/Components/BaseChips/BaseChips';
import {AppStoreKeys} from '../../../AppStoreKeys';

export default {
    name: 'Chips',
    props: {...BaseChips.props},
    components: {BaseChips},
    computed: {
        breakpoint()
        {
            return this.$root.appContext.store.get(AppStoreKeys.BREAKPOINT);
        }
    },
    methods: {
        itemLabelFormatter(item): string
        {
            if (this.breakpoint.is('<', 'sm')) {
                return maxLength(item.label, 30);
            }
            return item.label;
        }
    }
};
