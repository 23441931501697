var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-chips",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u([
            {
              key: "chipLabel",
              fn: function (props) {
                return [
                  _c("span", { attrs: { title: props.item.label } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.itemLabelFormatter(props.item)) +
                        "\n        "
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        "base-chips",
        Object.assign({}, _vm.$props, _vm.$attrs),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }