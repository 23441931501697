

































import BaseSelectBox from '@labor-digital/vuetiful/src/Components/BaseSelectBox/BaseSelectBox';
import Icon from '../../Misc/Icon/Icon.vue';
import IconHoverMixin from '../../Misc/Icon/IconHoverMixin';
import Checkbox from '../Checkbox/Checkbox.vue';
import Chips from '../Chips/Chips.vue';
import InputField from '../InputField/InputField.vue';

export default {
    name: 'MultiSelectBox',
    components: {Icon, BaseSelectBox},
    mixins: [IconHoverMixin],
    data()
    {
        return {
            components: {
                checkbox: Checkbox,
                input: InputField,
                chips: Chips
            }
        };
    }
};
