






















































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import InputField from '../../../../../Form/InputField/InputField.vue';
import Btn from '../../../../../Misc/Btn/Btn';
import RequestEmployeesModuleStep31 from './RequestEmployeesModuleStep31.vue';
import StepMixin from './StepMixin';

export default {
    name: 'RequestEmployeesModuleStep3',
    components: {InputField, Btn},
    mixins: [StepMixin],
    computed: {
        isEmailValid(): boolean
        {
            return !isEmpty(this.data.email) && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.data.email);
        },
        emailErrorMessage(): string | null
        {
            return this.isEmailValid || this.data.email === '' ? null : this.$t('requestEmployees.form.email.error');
        }
    },
    methods: {
        onShow()
        {
            const that = this;
            this.$emit('expand', {
                extends: RequestEmployeesModuleStep31,
                computed: {
                    parentComponent: () => this,
                    errors: () => {
                        return that.errors;
                    }
                }
            });
        },
        validate(): boolean
        {
            // Test if expanded content is valid
            const isChildValid = this.$parent.$refs.expandedContent ? this.$parent.$refs.expandedContent.validate() : true;

            return this.isEmailValid && isChildValid;
        }
    }
};
