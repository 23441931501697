/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.19 at 19:01
 */

import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';

export default {
    props: {
        context: null as PageContext,
        data: {
            required: true,
            type: Object
        } as PlainObject,
        step: {
            required: true,
            type: Number
        },
        isExpanded: {
            default: false,
            type: [Boolean, Object]
        }
    },
    computed: {
        isFormValid(): boolean
        {
            return this.validate();
        }
    },
    methods: {
        validate(): boolean
        {
            return true;
        },
        onButtonClick()
        {
            if (this.isFormValid) {
                this.$emit('goTo', this.step + 1);
            }
        }
    }
};
