

















































import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isNumeric} from '@labor-digital/helferlein/lib/Types/isNumeric';
import {Branch} from '../../../../../../Interface/Branch';
import InputField from '../../../../../Form/InputField/InputField.vue';
import MultiSelectBox from '../../../../../Form/MultiSelectBox/MultiSelectBox';
import SelectBox from '../../../../../Form/SelectBox/SelectBox';
import Btn from '../../../../../Misc/Btn/Btn';
import StepMixin from './StepMixin';

export default {
    name: 'RequestEmployeesModuleStep1',
    components: {MultiSelectBox, SelectBox, InputField, Btn},
    mixins: [StepMixin],
    data: function () {
        return {
            waitForBranchApiRequest: false,
            isZipValidInternal: false
        };
    },
    computed: {
        isZipValid(): boolean
        {
            // Special condition if the branch was injected into the module
            if (this.data.branch !== null && this.data.zip === this.data.branch.zip + '') {
                return true;
            }
            return this.isZipValidInternal;
        },
        zipErrorMessage(): string | null
        {
            if (this.waitForBranchApiRequest) {
                return null;
            }
            return this.isZipValid || this.data.zip === '' ? null : this.$t('requestEmployees.form.zip.error');
        }
    },
    methods: {
        validate(): boolean
        {
            return this.isZipValid;
        }
    },
    watch: {
        'data.zip': function (n) {
            this.isZipValidInternal = false;

            if (isEmpty(n) || !isNumeric(n)) {
                return;
            }

            debouncePromise('requestEmployeeZip', () => {
                this.waitForBranchApiRequest = true;
                return this.context.resourceApi.getAdditional('branch', 'byZip/' + n);
            }, 600, true)
                .then(response => {
                    this.data.branch = response.getAll() as Branch;
                    this.isZipValidInternal = true;
                })
                .catch(() => {
                    this.isZipValidInternal = false;
                })
                .then(() => {
                    this.waitForBranchApiRequest = false;
                });
        }
    }
};
