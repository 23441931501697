var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "requestEmployees__step1" }, [
    _c(
      "div",
      { staticClass: "occupation" },
      [
        _c("label", { attrs: { for: "request-employees-occupation" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.occupation.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(_vm._s(_vm.$t("requestEmployees.form.occupation.prefix"))),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-occupation",
            type: "text",
            placeholder: _vm.$t("requestEmployees.form.occupation.placeholder"),
          },
          model: {
            value: _vm.data.occupation,
            callback: function ($$v) {
              _vm.$set(_vm.data, "occupation", $$v)
            },
            expression: "data.occupation",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "zip" },
      [
        _c("label", { attrs: { for: "request-employees-zip" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.zip.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("requestEmployees.form.zip.prefix")))]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-zip",
            type: "text",
            error: _vm.zipErrorMessage,
            "show-spinner": _vm.waitForBranchApiRequest,
            placeholder: _vm.$t("requestEmployees.form.zip.placeholder"),
          },
          model: {
            value: _vm.data.zip,
            callback: function ($$v) {
              _vm.$set(_vm.data, "zip", $$v)
            },
            expression: "data.zip",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c(
          "btn",
          {
            attrs: { "is-disabled": !_vm.isFormValid },
            nativeOn: {
              click: function ($event) {
                return _vm.onButtonClick.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("requestEmployees.form.step1.submit")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }