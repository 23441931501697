var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-select-box",
    _vm._g(
      _vm._b(
        {
          staticClass: "multiSelectBox",
          attrs: {
            "is-multi-select": true,
            "display-as-chip": "",
            "chips-component": _vm.components.chips,
            "checkbox-component": _vm.components.checkbox,
            "input-field-component": _vm.components.input,
          },
          scopedSlots: _vm._u([
            {
              key: "input-end",
              fn: function () {
                return [
                  _c(
                    "icon",
                    _vm._b(
                      {
                        staticClass: "multiSelectBox__icon",
                        attrs: { icon: "arrowDown" },
                      },
                      "icon",
                      _vm.iconHoverProps(),
                      false
                    )
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "base-select-box",
        Object.assign(
          {},
          _vm.$props,
          _vm.$attrs,
          _vm.iconHoverContainerProps()
        ),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }